@import url("https://fonts.googleapis.com/css2?family=Prata&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
* {
  margin: 0px;
  padding: 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
ul {
  letter-spacing: 0.1px;
  text-decoration: none;
  list-style-type: none;
  font-weight: 400;
  line-height: 1.5;
}

body {
  overflow-x: hidden;
}

p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif;
  font-size: 18px;
}

h2 {
  font-size: 38px;
  font-family: "Prata", serif;
}

h3 {
  font-size: 20px;
  font-family: "Prata", serif;
}

.primary-color-bg {
  background: radial-gradient(circle at -20% 120%, #212121, #202020, #1e1e1e, #1b1b1b, #171717, #141414, #101010, #0d0d0d, #0b0b0b, #0a0a0a);
}

.secondary-color-bg-gradient {
  background: radial-gradient(circle at 70% 10%, #171717, #161616, #131313, #0f0f0f, #0b0b0b, #070707, #040404, #030303);
}

.tertiary-color-bg-solid {
  background-color: #030303fb;
}

.complementary-admin-bg-solid {
  background-color: #202225;
}

.admin-users-bg-gradient {
  background: radial-gradient(circle at 20% 120%, #020847, #020d50, #041159, #051562, #06196c, #071d76, #08227f, #082689, #082b94, #062f9e, #0434a8, #0039b3);
}

.admin-account-bg-gradient {
  background: radial-gradient(circle at 10% 120%, #3b0000, #3d0001, #420002, #4b0004, #550006, #610007, #6d0007, #790006, #840004, #8c0002, #920001, #940000);
}

.dark-mode-bg {
  background-color: #36393f;
}

.font-primary {
  font-family: "Prata", serif;
}

.font-secondary {
  font-family: "Lato", sans-serif;
}

.font-color-secondary {
  color: #ffcf54;
}

.header-tagline {
  font-size: clamp(46px, 5vw, 92px);
  max-width: 717px;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 1px;
}

.header-tagline-full-width {
  font-size: clamp(32px, 5vw, 92px);
  width: 100%;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 1px;
}

.logo {
  width: 60px;
}

.menu-icon {
  color: #fff;
  background-color: #fff;
}

header {
  position: relative;
  color: #fff;
}

header a {
  color: #fff;
}

header nav {
  padding: 1.5rem 0;
}

header nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

header nav ul li {
  font-weight: bold;
}

header nav ul ul li {
  margin: 0 1rem;
}

header nav .nav-menu {
  display: none;
}

header .header-details {
  padding: 10rem 0;
}

header .illustration-offset {
  position: absolute;
  right: 0;
  z-index: 0;
  top: 6rem;
  overflow: hidden;
}

header .illustration-offset img {
  width: 700px;
}

header .banner-category {
  background: #ffffff;
  -webkit-box-shadow: 0px 1px 29px 12px rgba(0, 0, 0, 0.02);
          box-shadow: 0px 1px 29px 12px rgba(0, 0, 0, 0.02);
  color: #0f0f0f;
  width: 100%;
  padding: 2rem;
}

header .div-offset {
  position: absolute;
  bottom: -9rem;
  width: 70%;
  max-width: 1150px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
          box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
}

header .div-offset-contact {
  border-radius: 1rem;
  z-index: 2;
  position: absolute;
  bottom: -30rem;
  width: 90%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
          box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
}

header .div-offset-admin {
  position: absolute;
  top: 20rem;
  width: 90%;
  max-width: 1300px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

header .div-gradient {
  -webkit-box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
          box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
}

header .select {
  width: 300px;
}

header .select-medium {
  width: 370px;
}

a {
  cursor: pointer;
}

#button-style {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 0;
}

#button-style-dark {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 0;
  background-color: #0f0f0f;
}

#button-style-outlined-dark {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 0;
  border: 1px solid #0f0f0f;
  color: #0f0f0f;
}

#button-style-light {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 0;
  background-color: #fff;
  color: #0f0f0f;
}

.flex-default {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.space-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flex-flow-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 95vh;
}

.box-md {
  width: 60%;
}

.box-sm {
  width: 40%;
}

.graphics {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.graphics img {
  max-width: 80%;
}

.flex-no-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: wrap;
      flex-flow: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.move-left-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.flex-flow-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flex-flow-wrap-default {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.flex-flow-wrap-left-align {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flex-flow-wrap-default-admin {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-flow-wrap-default-align {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.grid-center {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flex-flow-wrap-default-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.flex-end {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.flex-end p {
  cursor: pointer;
}

.section-title {
  padding: 8rem 0;
  margin-top: 17rem;
}

.properties {
  margin: 2rem 0 10rem 0;
}

.properties-solo {
  margin: 8rem 0 10rem 0;
}

.properties-specific {
  margin: 0 0 3rem 0;
}

.pad-y-lt {
  padding: 5px 0;
}

.pad-y-sm {
  padding: 1rem 0;
}

.pad-x-sm {
  padding: 0 1rem;
}

.pad-xy-sm {
  padding: 1rem;
}

.pad-y-md {
  padding: 5rem 0;
}

.pad-x-md {
  padding: 5rem 0;
}

.pad-xy-md {
  padding: 5rem;
}

.section-div-offset-margin {
  margin-top: 12rem;
}

.text-center {
  text-align: center;
}

.subtitle {
  color: #757575;
}

.error-red {
  color: #cd3d64;
  font-weight: bold;
}

.error-red-background {
  background-color: #cd3d64;
}

.middle-fix {
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  z-index: 2500 !important;
}

.icon {
  width: 33px;
  -o-object-fit: contain;
     object-fit: contain;
}

.graphics-offset {
  position: absolute;
  bottom: 0;
  left: -5rem;
}

.graphics-offset img {
  width: 120%;
}

.small {
  font-size: 14px;
}

.bottomBorder {
  border-bottom: 1px solid #d8d8d8;
}

.paginationBtns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.paginationBtns a {
  padding: 1rem;
  border: 1px solid rgba(245, 245, 245, 0.959);
  -webkit-transition: 0.3s all ease-in;
  transition: 0.3s all ease-in;
}

.paginationBtns a:hover {
  padding: 1rem;
  border: 1px solid rgba(245, 245, 245, 0.959);
  background-color: #202020;
  color: #fff;
}

.paginationActive a {
  border: 1px solid rgba(245, 245, 245, 0.959);
  background-color: #202020;
  color: #fff;
}

.uppercase {
  text-transform: uppercase;
}

.box-height {
  width: 380px;
  overflow-x: hidden;
  cursor: pointer;
  -webkit-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in;
}

.box-height:hover {
  -webkit-box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.151);
          box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.151);
}

.contact-gradient {
  background: radial-gradient(circle at 10% -20%, #330096, #270a8a, #1d107e, #131272, #0c1365, #071358, #06134c, #07113f, #0a0e32, #0a0a26, #06051a, #00000a);
}

.image-height-property {
  height: 500px;
  overflow-y: hidden;
  display: -ms-grid;
  display: grid;
  place-items: center;
  background: radial-gradient(circle at 30% 50%, #8f8f8f, #919191, #979797, #a0a0a0, #acacac, #b9b9b9, #c6c6c6, #d3d3d3, #dfdfdf, #e9e9e9, #f0f0f0, #f2f2f2);
}

.propertyImage {
  width: 320px;
  -o-object-fit: contain;
     object-fit: contain;
  overflow-x: hidden;
}

.overflow-hidden {
  overflow-x: hidden;
}

.icon-container {
  border-radius: 0.3rem;
  padding: 0.3rem;
  display: -ms-grid;
  display: grid;
  place-items: center;
}

.icon-dynamic {
  color: gray;
}

.admin-nav {
  padding: 0.5rem 0.8rem;
  cursor: pointer;
}

.admin-nav:hover {
  background-color: #3d3d3d;
}

.admin-nav:hover .icon-dynamic {
  color: #fff;
}

#active {
  background-color: #3d3d3d !important;
}

#active .icon-dynamic {
  color: #fff;
}

.box-23 {
  width: 280px;
  margin-bottom: 4rem;
}

.box-23 img {
  margin-bottom: 15px;
}

.box-23 h3 {
  margin-bottom: 15px;
}

.box-23 p {
  line-height: 1.8;
}

.box-23 ul li {
  margin: 5px 0;
  line-height: 1.5;
  font-size: 16px;
  color: #c3c4c7;
}

.box-23 ul li:nth-child(1) {
  font-weight: bold;
  font-size: 16px;
  color: #fff;
}

.about-us h1 {
  font-size: clamp(24px, 5vw, 92px);
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 1px;
  color: #ffcf54 !important;
}

.box-600 {
  max-width: 1000px;
  margin: 2rem 0;
  -webkit-box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(30, 135, 255, 0.692);
          box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(30, 135, 255, 0.692);
  border-radius: 1rem !important;
}

.box-300 {
  max-width: 375px !important;
}

.box-50 {
  width: 420px;
  margin: 2rem 0;
}

.box-50 h1 {
  font-size: clamp(24px, 5vw, 92px);
  max-width: 717px;
  line-height: 1.5;
  font-weight: 400;
  color: #ffcf54 !important;
}

.box-50 p {
  font-weight: 400;
  line-height: 1.5;
  font-size: clamp(16px, 3vw, 20px);
}

.cursor-pointer {
  cursor: pointer;
}

.slide-hover p {
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}

.slide-hover div {
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}

.slide-hover:hover p {
  margin-right: 0.3rem;
}

.slide-hover:hover div {
  margin-left: 0.3rem;
}

.font-light {
  color: #fff;
}

.font-dark {
  color: #0f0f0f !important;
}

.top-border-div {
  border-top: 1px solid #2e2e2e;
}

.aside-fixed-width {
  width: 160px;
  height: 100%;
}

.position-relative {
  position: relative;
}

.side-nav {
  z-index: 1;
  position: fixed;
  top: 0;
}

.container-admin {
  padding-left: 3.5rem;
}

.position-absolute-right {
  position: absolute;
  right: 0.2rem;
  top: 1.3rem;
  z-index: 1;
  cursor: pointer;
}

.full-height {
  height: 100vh;
}

.height-500 {
  height: 400px;
}

.full-width {
  width: 100% !important;
}

.image-sm {
  width: 50px;
}

.p-fixed {
  min-width: 100px !important;
}

.width-sm {
  max-width: 600px !important;
  margin: 0 auto;
}

.property-image-lg {
  width: 700px;
  max-height: 600px;
  -o-object-fit: contain;
     object-fit: contain;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

#display-none {
  display: none;
}

#flex-space-between {
  display: block;
}

.truncate {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncateHeight {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

#display-block {
  display: block !important;
}

@media screen and (max-width: 992px) {
  .contact-dynamic-padding {
    margin-top: 5rem;
  }
  .banner-category {
    margin-top: 2rem;
  }
  .graphics {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    padding: 2rem 0;
  }
  .graphics img {
    min-width: 100% !important;
  }
  .box-sm {
    width: 100%;
  }
  .move-left-right :nth-child(1) {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .move-left-right :nth-child(2) {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .flex-flow-wrap {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .left-banner {
    display: none;
  }
  header .div-offset {
    position: absolute;
    bottom: -9rem;
    width: 90%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
            box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
  }
  header .div-offset-admin {
    position: absolute;
    bottom: -5rem;
    left: 44%;
    -webkit-transform: translateX(-44%);
            transform: translateX(-44%);
  }
  header .div-offset-bg {
    width: 95%;
    -webkit-box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
            box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
  }
  header .nav-links {
    display: none;
  }
  header nav .nav-menu {
    display: block;
  }
}

@media screen and (max-width: 1113px) {
  .illustration-offset {
    display: none;
  }
}

@media screen and (max-width: 1229px) {
  header .div-offset {
    position: absolute;
    bottom: -11rem;
    width: 90%;
    max-width: 1150px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
            box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
  }
  .section-title {
    margin-top: 10rem;
  }
}
