@import url("https://fonts.googleapis.com/css2?family=Prata&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
ul {
  letter-spacing: 0.1px;
  text-decoration: none;
  list-style-type: none;
  font-weight: 400;
  line-height: 1.5;
}
body {
  overflow-x: hidden;
}
p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif;
  font-size: 18px;
}
h2 {
  font-size: 38px;
  font-family: "Prata", serif;
}
h3 {
  font-size: 20px;
  font-family: "Prata", serif;
}
//variables
$base-color: #0f0f0f;
$secondary-color: #ffcf54;
$white-color: #fff;
$font-subtitle: #757575;
$error-red: #cd3d64;

//colors
.primary-color-bg {
  // background-color: $base-color;
  background: radial-gradient(
    circle at -20% 120%,
    #212121,
    #202020,
    #1e1e1e,
    #1b1b1b,
    #171717,
    #141414,
    #101010,
    #0d0d0d,
    #0b0b0b,
    #0a0a0a
  );
}
.secondary-color-bg-gradient {
  background: radial-gradient(
    circle at 70% 10%,
    #171717,
    #161616,
    #131313,
    #0f0f0f,
    #0b0b0b,
    #070707,
    #040404,
    #030303
  );
}
.tertiary-color-bg-solid {
  background-color: #030303fb;
}
.complementary-admin-bg-solid {
  background-color: #202225;
}
.admin-users-bg-gradient {
  background: radial-gradient(
    circle at 20% 120%,
    #020847,
    #020d50,
    #041159,
    #051562,
    #06196c,
    #071d76,
    #08227f,
    #082689,
    #082b94,
    #062f9e,
    #0434a8,
    #0039b3
  );
}
.admin-account-bg-gradient {
  background: radial-gradient(
    circle at 10% 120%,
    #3b0000,
    #3d0001,
    #420002,
    #4b0004,
    #550006,
    #610007,
    #6d0007,
    #790006,
    #840004,
    #8c0002,
    #920001,
    #940000
  );
}
.dark-mode-bg {
  background-color: #36393f;
}
.font-primary {
  font-family: "Prata", serif;
}
.font-secondary {
  font-family: "Lato", sans-serif;
}
.font-color-secondary {
  color: $secondary-color;
}
.header-tagline {
  font-size: clamp(46px, 5vw, 92px);
  max-width: 717px;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 1px;
}
.header-tagline-full-width {
  font-size: clamp(32px, 5vw, 92px);
  width: 100%;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 1px;
}
.logo {
  width: 60px;
}
.menu-icon {
  color: #fff;
  background-color: #fff;
}
header {
  position: relative;
  color: $white-color;
  a {
    color: #fff;
  }
  nav {
    padding: 1.5rem 0;
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      li {
        font-weight: bold;
      }
      ul {
        li {
          margin: 0 1rem;
        }
      }
    }
    .nav-menu {
      display: none;
    }
  }

  .header-details {
    padding: 10rem 0;
  }

  .illustration-offset {
    position: absolute;
    right: 0;
    z-index: 0;
    top: 6rem;
    overflow: hidden;
    img {
      width: 700px;
    }
  }

  .banner-category {
    background: #ffffff;
    box-shadow: 0px 1px 29px 12px rgba(0, 0, 0, 0.02);
    color: $base-color;
    width: 100%;
    padding: 2rem;
  }

  .div-offset {
    position: absolute;
    bottom: -9rem;
    width: 70%;
    max-width: 1150px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
      0 30px 60px -30px rgba(0, 0, 0, 0.3);
  }
  .div-offset-contact {
    border-radius: 1rem;
    z-index: 2;
    position: absolute;
    bottom: -30rem;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
      0 30px 60px -30px rgba(0, 0, 0, 0.3);
  }
  .div-offset-admin {
    position: absolute;
    top: 20rem;
    width: 90%;
    max-width: 1300px;
    left: 50%;
    transform: translateX(-50%);
  }
  .div-gradient {
    box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
      0 30px 60px -30px rgba(0, 0, 0, 0.3);
  }
  .select {
    width: 300px;
  }
  .select-medium {
    width: 370px;
  }
}

a {
  cursor: pointer;
}
#button-style {
  box-shadow: none;
  border-radius: 0;
  // background-color: $base-color;
}
#button-style-dark {
  box-shadow: none;
  border-radius: 0;
  background-color: $base-color;
}
#button-style-outlined-dark {
  box-shadow: none;
  border-radius: 0;
  border: 1px solid $base-color;
  color: $base-color;
}
#button-style-light {
  box-shadow: none;
  border-radius: 0;
  background-color: #fff;
  color: $base-color;
}
//components
.flex-default {
  display: flex;
  align-items: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-flow-row {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  height: 95vh;
}
.box-md {
  width: 60%;
}
.box-sm {
  width: 40%;
}
.graphics {
  display: flex;
  justify-content: center;
  img {
    max-width: 80%;
  }
}
.flex-no-wrap {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  width: 100%;
  flex-direction: row;
}
.move-left-right {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  flex-direction: row;
}
.flex-flow-wrap {
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;
  align-items: center;
}
.flex-flow-wrap-default {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // align-items: center;
}
.flex-flow-wrap-left-align {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.flex-flow-wrap-default-admin {
  display: flex;
}
.flex-flow-wrap-default-align {
  display: flex;
  position: relative;
  align-items: center;
}
.grid-center {
  display: grid;
  align-items: center;
}
.flex-flow-wrap-default-center {
  display: flex;
  position: relative;
  justify-content: center;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  p {
    cursor: pointer;
  }
}

//sections
.section-title {
  padding: 8rem 0;
  margin-top: 17rem;
}

.properties {
  margin: 2rem 0 10rem 0;
}
.properties-solo {
  margin: 8rem 0 10rem 0;
}
.properties-specific {
  margin: 0 0 3rem 0;
}
//paddings
.pad-y-lt {
  padding: 5px 0;
}
.pad-y-sm {
  padding: 1rem 0;
}
.pad-x-sm {
  padding: 0 1rem;
}
.pad-xy-sm {
  padding: 1rem;
}
.pad-y-md {
  padding: 5rem 0;
}
.pad-x-md {
  padding: 5rem 0;
}
.pad-xy-md {
  padding: 5rem;
}

//margin
.section-div-offset-margin {
  margin-top: 12rem;
}
//alignments
.text-center {
  text-align: center;
}

//color variations
.subtitle {
  color: $font-subtitle;
}
.error-red {
  color: $error-red;
  font-weight: bold;
}
.error-red-background {
  background-color: $error-red;
}
//loading
.middle-fix {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%);
  z-index: 2500 !important;
}

//images
.icon {
  width: 33px;
  object-fit: contain;
}
.graphics-offset {
  position: absolute;
  bottom: 0;
  left: -5rem;
  img {
    width: 120%;
  }
}

//font sizing
.small {
  font-size: 14px;
}

//div custom
.bottomBorder {
  border-bottom: 1px solid rgb(216, 216, 216);
}
.paginationBtns {
  display: flex;
}
.paginationBtns a {
  padding: 1rem;
  border: 1px solid rgba(245, 245, 245, 0.959);
  transition: 0.3s all ease-in;
}
.paginationBtns a:hover {
  padding: 1rem;
  border: 1px solid rgba(245, 245, 245, 0.959);
  background-color: rgb(32, 32, 32);
  color: #fff;
}
.paginationActive a {
  border: 1px solid rgba(245, 245, 245, 0.959);
  background-color: rgb(32, 32, 32);
  color: #fff;
}
.uppercase {
  text-transform: uppercase;
}
.box-height {
  width: 380px;
  overflow-x: hidden;
  cursor: pointer;
  transition: all 0.25s ease-in;

  &:hover {
    box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
      0 30px 60px -30px rgba(0, 0, 0, 0.151);
  }
}
.contact-gradient {
  background: radial-gradient(
    circle at 10% -20%,
    #330096,
    #270a8a,
    #1d107e,
    #131272,
    #0c1365,
    #071358,
    #06134c,
    #07113f,
    #0a0e32,
    #0a0a26,
    #06051a,
    #00000a
  );
}
.image-height-property {
  height: 500px;
  overflow-y: hidden;
  display: grid;
  place-items: center;
  background: radial-gradient(
    circle at 30% 50%,
    #8f8f8f,
    #919191,
    #979797,
    #a0a0a0,
    #acacac,
    #b9b9b9,
    #c6c6c6,
    #d3d3d3,
    #dfdfdf,
    #e9e9e9,
    #f0f0f0,
    #f2f2f2
  );
}
.propertyImage {
  width: 320px;
  object-fit: contain;
  overflow-x: hidden;
}
.overflow-hidden {
  overflow-x: hidden;
}
.icon-container {
  // background-color: gray;
  border-radius: 0.3rem;
  padding: 0.3rem;
  display: grid;
  place-items: center;
}
.icon-dynamic {
  color: gray;
}
.admin-nav {
  padding: 0.5rem 0.8rem;
  cursor: pointer;
}
.admin-nav:hover {
  background-color: rgb(61, 61, 61);
}
.admin-nav:hover .icon-dynamic {
  color: #fff;
}
#active {
  .icon-dynamic {
    color: #fff;
  }
  background-color: rgb(61, 61, 61) !important;
}
.box-23 {
  img {
    margin-bottom: 15px;
  }
  h3 {
    margin-bottom: 15px;
  }
  p {
    line-height: 1.8;
  }
  width: 280px;
  margin-bottom: 4rem;

  ul {
    li {
      margin: 5px 0;
      line-height: 1.5;
      font-size: 16px;
      color: #c3c4c7;
      &:nth-child(1) {
        font-weight: bold;
        font-size: 16px;
        color: #fff;
      }
    }
  }
  // &:hover {
  //   box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
  //     0 30px 60px -30px rgba(0, 0, 0, 0.3);
  // }
}
.about-us {
  h1 {
    font-size: clamp(24px, 5vw, 92px);
    line-height: 1.5;
    font-weight: 400;
    letter-spacing: 1px;
    color: $secondary-color !important;
  }
}
.box-600 {
  max-width: 1000px;
  margin: 2rem 0;
  box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
    0 30px 60px -30px rgba(30, 135, 255, 0.692);
  border-radius: 1rem !important;
}
.box-300 {
  max-width: 375px !important;
}
.box-50 {
  width: 420px;
  margin: 2rem 0;

  h1 {
    font-size: clamp(24px, 5vw, 92px);
    max-width: 717px;
    line-height: 1.5;
    font-weight: 400;
    // letter-spacing: 1px;
    color: $secondary-color !important;
  }
  p {
    font-weight: 400;
    // font-size: 20px;
    line-height: 1.5;
    // letter-spacing: 0.09em;
    font-size: clamp(16px, 3vw, 20px);
  }
}
.cursor-pointer {
  cursor: pointer;
}

//animation
.slide-hover {
  p {
    transition: all 0.1s ease-in;
  }
  div {
    transition: all 0.5s ease-in;
  }
}

.slide-hover:hover {
  p {
    margin-right: 0.3rem;
  }
  div {
    margin-left: 0.3rem;
  }
}

.font-light {
  color: $white-color;
}

.font-dark {
  color: $base-color !important;
}

//border
.top-border-div {
  border-top: 1px solid #2e2e2e;
}
.aside-fixed-width {
  width: 160px;
  height: 100%;
}
//postition
.position-relative {
  position: relative;
}
.side-nav {
  z-index: 1;
  position: fixed;
  top: 0;
}
.container-admin {
  padding-left: 3.5rem;
}
.position-absolute-right {
  position: absolute;
  right: 0.2rem;
  top: 1.3rem;
  z-index: 1;
  cursor: pointer;
}

//height
.full-height {
  height: 100vh;
}
.height-500 {
  height: 400px;
}
//width
.full-width {
  width: 100% !important;
}
.image-sm {
  width: 50px;
}
.p-fixed {
  min-width: 100px !important;
}
.width-sm {
  max-width: 600px !important;
  margin: 0 auto;
}
.property-image-lg {
  width: 700px;
  max-height: 600px;
  object-fit: contain;
}
//display
.display-none {
  display: none;
}
.display-block {
  display: block;
}
#display-none {
  display: none;
}
#flex-space-between {
  display: block;
}
.truncate {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncateHeight {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
#display-block {
  display: block !important;
}
@media screen and (max-width: 992px) {
  .contact-dynamic-padding {
    margin-top: 5rem;
  }
  .banner-category {
    margin-top: 2rem;
  }

  .graphics {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 2rem 0;
    img {
      min-width: 100% !important;
    }
  }
  .box-sm {
    width: 100%;
  }
  .move-left-right :nth-child(1) {
    order: 2;
  }
  .move-left-right :nth-child(2) {
    order: 1;
  }
  .flex-flow-wrap {
    justify-content: center;
  }
  .left-banner {
    display: none;
  }
  header {
    .div-offset {
      position: absolute;
      bottom: -9rem;
      width: 90%;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
        0 30px 60px -30px rgba(0, 0, 0, 0.3);
    }

    .div-offset-admin {
      position: absolute;
      bottom: -5rem;

      left: 44%;
      transform: translateX(-44%);
    }
    .div-offset-bg {
      width: 95%;
      box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
        0 30px 60px -30px rgba(0, 0, 0, 0.3);
    }
    .nav-links {
      display: none;
    }
    nav {
      .nav-menu {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 1113px) {
  .illustration-offset {
    display: none;
  }
}

@media screen and (max-width: 1229px) {
  header {
    .div-offset {
      position: absolute;
      bottom: -11rem;
      width: 90%;
      max-width: 1150px;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
        0 30px 60px -30px rgba(0, 0, 0, 0.3);
    }
  }
  .section-title {
    margin-top: 10rem;
  }
}
